<template>
  <footer class="footer">
    <div class="footer-container">
      <div class="footer-logo">
        <img :src="require('@/assets/images/logo-2.png')" alt="footer-logo">
      </div>
      <div class="android__container">
        <div class="android">
            <p class="android__p">DOWNLOAD THE OFFICIAL APP HERE:
                <a class="android__download" href="https://images.betimages.com/Apps/abcwagering/abcwagering.apk">DOWNLOAD</a>
            </p>
            <div class="android__content-disclaimer">
                <a href="https://images.betimages.com/Apps/abcwagering/abcwagering.apk"><img class="android__logo" src="@/assets/images/android.webp" alt="android_logo"></a>
                <p class="android__download">Available for ANDROID only.</p>
            </div>
        </div>
      </div>
      <div class="footer-list">
        <ul>
            <li v-for="(slide, index)  in routesColumn1" :key="index">
              <a v-if="index <= 1" :href="slide.route" target="_blank">{{ slide.name }}</a>
              <a v-else :href="slide.route">{{ slide.name }}</a>
            </li>
        </ul>
      </div>
    </div>
  </footer>
</template>


<script>
/*   import AgentButton from "@/components/agent-button.vue"
  import MobileMenu from '@/components/mobile-menu.vue' */
  import { useI18n } from 'vue-i18n';
  import { reactive, watch } from 'vue';
  
  export default {
    name: "FooterComponent",
    
    components: {
/*       AgentButton,
      MobileMenu */
    },
    setup() {
      const { t , locale }  = useI18n();

      const androidApp = "#";

      const routesColumn1= reactive( [
          {
            name       :  t("footerColum1.0.name"),
            route      :  t("footerColum1.0.route")
          },
          {
            name       :   t("footerColum1.1.name"),
            route      :   t("footerColum1.1.route")
          },
          {
            name       :   t("footerColum1.2.name"),
            route      :   t("footerColum1.2.route")
          },
          {
            name       :   t("footerColum1.3.name"),
            route      :   t("footerColum1.3.route")
          },
          {
            name       :   t("footerColum1.4.name"),
            route      :   t("footerColum1.4.route")
          }
        ])

             // Observar los cambios de idioma y actualiza los slides
      const updateSlides = () => {
        routesColumn1.forEach((opcion, index) => {
          opcion.name = t(`footerColum1.${index}.name`);
          opcion.route = t(`footerColum1.${index}.route`);
        });
      };

      // Escuchar los cambios de idioma y actualiza los slides
      watch(locale, () => {
        updateSlides();
      });
      return {
        routesColumn1,
        androidApp
      }
    },
  };
</script>
